<template>
  <div>
    <div v-if="ruleForm">
      <div v-if="user != null && user.role > 0 && user.role != 50">
        <router-link to="/user/list" class="text-grey-light font-18"
          ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
        >
      </div>
      <h3 class="text-center">ข้อมูลส่วนตัว</h3>

      <div v-if="user.role != 50 && user.role != 60">
        <div
          v-if="ruleForm.profileImg == 'undefined'"
          class="text-center profile-img"
        >
          <i class="fas fa-user-alt"></i>
        </div>

        <div v-else class="text-center profile-img">
          <img :src="imgUrl + ruleForm.profileImg" />
        </div>
      </div>
      <div class="is-flex js-center ai-center" v-if="user.role == 60">
        <div class="box--profile overflow-hidden el-upload-list--picture-card">
          <avatar
            :username="ruleForm.name"
            :rounded="false"
            :src="`${
              ruleForm.profileImg != 'undefined' && ruleForm.profileImg
                ? imgUrl + ruleForm.profileImg
                : ''
            }`"
            :size="148"
          >
          </avatar>
        </div>
      </div>

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-row :gutter="20">
          <!-- <el-col :span="24" :offset="0">
            <el-form-item label="คำนำหน้าชื่อ">
              <el-select
                :disabled="disable"
                v-model="ruleForm.titleName"
                placeholder="กรุณาเลือกคำนำหน้าชื่อ"
                class="w-100"
                @change="
                  ruleForm.titleName == 4
                    ? (ruleForm.education = 4)
                    : ruleForm.titleName == 1
                    ? (ruleForm.gender = 1)
                    : ruleForm.titleName == 2 || ruleForm.titleName == 3
                    ? (ruleForm.gender = 2)
                    : null
                "
              >
                <el-option
                  v-for="data in titleNameOption"
                  :label="data.label"
                  :value="data.value"
                  :key="data.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :md="24" :offset="0">
            <el-form-item label="ชื่อ">
              <el-input v-model="ruleForm.name" :disabled="disable"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :offset="0">
            <el-form-item label="นามสกุล">
              <el-input
                v-model="ruleForm.surname"
                :disabled="disable"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="24" :offset="0">
            <el-form-item label="เพศ">
              <el-radio-group v-model="ruleForm.gender" :readonly="disable">
                <el-radio
                  :label="1"
                  :disabled="ruleForm.titleName == 2 || ruleForm.titleName == 3"
                  >ชาย</el-radio
                >
                <el-radio :label="2" :disabled="ruleForm.titleName == 1"
                  >หญิง</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :md="24" :offset="0">
            <el-form-item label="อายุ">
              <el-input
                type="age"
                v-model.number="ruleForm.age"
                v-mask="'##'"
                :disabled="disable"
              ></el-input>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :md="24" :offset="0">
            <el-form-item label="วุฒิการศึกษา">
              <el-select
                v-model="ruleForm.education"
                placeholder="กรุณาเลือกวุฒิการศึกษา"
                class="w-100"
                :disabled="disable"
              >
                <el-option
                  v-for="data in educationOption"
                  :label="data.label"
                  :value="data.value"
                  :key="data.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :md="24" :offset="0">
            <el-form-item label="อีเมล">
              <el-input v-model="ruleForm.email" :disabled="disable"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :offset="0">
            <el-form-item
              label="อีเมล (แก้ไข)"
              prop="emailEdit"
              v-if="user && user.role > 0"
            >
              <div>
                <div v-if="user.role == 60">
                  <el-button
                    v-if="!emailEdit"
                    type="danger"
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="
                      (emailEdit = true), (constEmail = ruleForm.emailEdit)
                    "
                  ></el-button>
                  <el-button
                    v-else
                    type="danger"
                    @click="editEmail()"
                    circle
                    size="mini"
                    ><i class="fas fa-save"></i
                  ></el-button>
                </div>
                <div>
                  <el-input
                    id="companyNameEdit"
                    :disabled="user.role == 60 ? !emailEdit : disable"
                    v-model="ruleForm.emailEdit"
                  ></el-input>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :md="24" :offset="0">
            <el-form-item label="เบอร์โทรศัพท์มือถือ">
              <el-input
                v-mask="'##########'"
                v-model="ruleForm.mobilephone"
                maxlength="13"
                :disabled="disable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :offset="0">
            <el-form-item
              label="เบอร์โทรศัพท์มือถือ (แก้ไข)"
              prop="mobilephoneEdit"
              v-if="user && user.role > 0"
            >
              <div>
                <div v-if="user.role == 60">
                  <el-button
                    v-if="!telEdit"
                    type="danger"
                    icon="el-icon-edit"
                    circle
                    size="mini"
                    @click="
                      (telEdit = true), (constTel = ruleForm.mobilephoneEdit)
                    "
                  ></el-button>
                  <el-button
                    v-else
                    type="danger"
                    @click="telEmail()"
                    circle
                    size="mini"
                    ><i class="fas fa-save"></i
                  ></el-button>
                </div>

                <div>
                  <el-input
                    v-mask="'##########'"
                    v-model="ruleForm.mobilephoneEdit"
                    maxlength="13"
                    :disabled="user.role == 60 ? !telEdit : disable"
                  ></el-input>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="text-center" v-if="user.role == 50">
        <el-button type="primary" @click="saveEditdata" class="mg-t-5"
          >บันทึก</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  props: {
    ruleForm: {
      type: Object,
      default: {},
    },
    disable: {
      type: Boolean,
      default: true,
    },
    validate: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Avatar,
  },
  methods: {
    telEmail() {
      if (
        this.ruleForm.mobilephoneEdit.length > 8 &&
        this.ruleForm.mobilephoneEdit.length < 11
      ) {
        this.$emit("saveData", this.ruleForm);
        this.telEdit = false;
      } else {
        this.$message.error("เบอร์โทรศัพท์มือถือ (แก้ไข) ให้ครบ 10 หลัก");
      }
    },
    editEmail() {
      this.$emit("saveData", this.ruleForm);
      this.emailEdit = false;
    },
    saveEditdata() {
      if (this.user.role == 60) {
        this.$emit("saveData", this.ruleForm);
        this.telEdit = false;
        this.emailEdit = false;
        // this.$message({
        //   showClose: true,
        //   message: "กรุณากรอกให้ถูกต้อง",
        //   type: "error"
        // });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.telEdit = false;
            this.emailEdit = false;
            this.$emit("saveData", this.ruleForm);
          } else {
            this.$message({
              showClose: true,
              message: "กรุณากรอกข้อมูลให้ครบ",
              type: "error",
            });
          }
        });
      }
    },
  },
  computed: {
    titleNameOption() {
      return this.$store.state.titleName;
    },
    educationOption() {
      return this.$store.state.education;
    },
  },
  data() {
    return {
      rules: {
        emailEdit: [
          // {
          //   message: "รูปแบบอีเมลไม่ถูกต้อง",
          //   trigger: "blur",
          //   type: "email"
          // }
          // {
          //   required: this.validate,
          //   message: "กรุณากรอกอีเมล",
          //   trigger: "blur"
          // }
        ],
        mobilephoneEdit: [
          // {
          //   required: this.validate,
          //   message: "กรุณากรอกเบอร์โทรศัพท์มือถือ",
          //   trigger: "blur"
          // },
          // {
          //   min: 10,
          //   max: 13,
          //   message: "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ",
          //   trigger: "blur"
          // }
        ],
      },
      telEdit: false,
      constTel: null,
      emailEdit: false,
      constEmail: null,
      imgUrl: process.env.VUE_APP_IMAGE_URL,
    };
  },
};
</script>

<style></style>
